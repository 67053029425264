import React from 'react';
import TextField from '@material-ui/core/TextField';
import { GoogleReCaptchaProvider, withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Button from '@material-ui/core/Button';

import "./ContactForm.css"


class ReCaptchaComponent extends React.Component {

    constructor() {
        super();
        this.state = { data: '' };
    }

    async componentDidMount() {
        /*
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');

        const StringData = await token;
        this.setState({ data: StringData })

        //console.log(this.state.data);

        //console.log('token above');

        document.getElementById("g-recaptcha-response").value = token;
        */
    }

    render() {
        return (<div><input value={this.state.data} id="g-recaptcha-response" type="hidden" name="g-recaptcha-response" />
        </div>);
    }
}


const YourReCaptchaComponent = withGoogleReCaptcha(ReCaptchaComponent);

export default class ContactForm extends React.Component {

    render() {
        return (
            <div className="card-panelz">

                <form className="col s12" action="/mail.php" method="POST">
                    <GoogleReCaptchaProvider
                        reCaptchaKey="6LckmWYaAAAAACyDe4pHme9wY5y-To4M1Q9ZXYTa"
                        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
                    >
                        <YourReCaptchaComponent />
                    </GoogleReCaptchaProvider>

                    <input id="last_name" type="hidden" value="Contact Form filled on the Website" />

                    <div className="row">

                        <div className="input-field col s12">
                            <TextField
                                required
                                id="outlined-required"
                                label="Full Name"
                                variant="outlined"
                                className="input-field-1"
                                name="fullname"  
                            />
                        </div>
                        <div className="input-field col s12">
                            <TextField
                                required
                                id="outlined-required2"
                                label="Email"
                                variant="outlined"
                                className="input-field-1"
                                name="email"
                            />
                        </div>
                        <div className="input-field col s12">
                            <TextField
                                required
                                id="outlined-required2"
                                label="Telephone"
                                variant="outlined"
                                className="input-field-1"
                                name="telephone"
                            />
                        </div>
                        <div className="input-field col s12">
                            <TextField
                                id="outlined-multiline-static"
                                label="Message"
                                multiline
                                rows={4}
                                variant="outlined"
                                className="input-field-1"
                                name="message"
                            />
                        </div>
                    </div>
                    <div className="input-field col s12">
                        <Button variant="contained" type="submit" name="submit" value="1" color="primary">Send</Button>
                    </div>
                </form>

            </div>
        );
    }
}